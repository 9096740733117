<template>
  <div
    ref="container"
    class="w-44 h-8 cursor-pointer flex items-center justify-center font-medium rounded-full border shadow-sm text-14px  focus:border-oCharcoal hover:border-oCharcoal  focus:outline-none"
    :class="
      `${getSelectedStatus ? 'bg-gray-800 text-oWhite' : 'text-oCharcoal'}`
    "
  >
    <div @click="handleSync('grid')" class="relative">
      <loading
        v-if="isLoading"
        :active="isLoading"
        :height="25"
        :opacity="0"
        :color="`#fff`"
        :loader="`bars`"
        :is-full-page="false"
        :class="`outline-none`"
      />
      <p v-if="isLoading">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </p>
      <p v-if="!isLoading">
        Sync With AI (BETA)
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SyncWithAIButton',
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    isClicked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isClickedInside: false,
    }
  },
  mounted() {
    // Add event listener to detect clicks outside of the button
    document.addEventListener('click', this.handleOutsideClick)
  },
  beforeDestroy() {
    // Remove event listener when component is destroyed
    document.removeEventListener('click', this.handleOutsideClick)
  },
  computed: {
    getSelectedStatus() {
      return (
        this.$store.getters['suggestiveStep/getPopViewStatus'] ||
        this.isClickedInside ||
        this.isClicked
      )
    },
  },
  methods: {
    handleSync() {
      this.$emit('sync')
    },
    handleOutsideClick(event) {
      // Check if the clicked element is outside of the button
      const container = this.$refs.container
      if (!container.contains(event.target)) {
        this.isClickedInside = false
      } else {
        this.isClickedInside = true
      }
    },
  },
}
</script>
<style lang=""></style>
