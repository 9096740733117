<template>
  <div
    ref="container"
    class="px-8 h-8 cursor-pointer flex items-center justify-center font-medium rounded-full border shadow-sm text-14px  focus:border-oCharcoal hover:border-oCharcoal  focus:outline-none"
    :class="`${isLoading ? 'bg-gray-800 text-oWhite' : 'text-oCharcoal'}`"
  >
    <div @click="handleSync" class="relative">
      <loading
        v-if="isLoading"
        :active="isLoading"
        :height="25"
        :opacity="0"
        :color="`#fff`"
        :loader="`bars`"
        :is-full-page="false"
        :class="`outline-none`"
      />
      <p v-if="isLoading">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </p>
      <p v-if="!isLoading">
        {{ syncText }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SyncButton',
  props: {
    syncText: {
      ype: String,
      default: 'Sync',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isClicked: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    handleSync() {
      this.$emit('sync')
    },
  },
}
</script>
<style lang=""></style>
