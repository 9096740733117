<script>
import {
  defineComponent,
  computed,
  watch,
  onMounted,
} from '@vue/composition-api'
import { useStore } from '@vueblocks/vue-use-vuex'
import { cloneDeep } from 'lodash'

import FSDropdown from './FSDropdown'
import FSTableFilterGroup from './FSTableFilterGroup'
import RangeSlider from './RangeSlider'

export default defineComponent({
  components: {
    FSDropdown,
    FSTableFilterGroup,
    RangeSlider,
  },
  props: {
    fstId: {
      type: String,
      required: true,
    },
    // force reset even if props.fstId === store.fsTable.fstId
    forceReset: {
      type: Boolean,
      default: false,
    },
    // slot width for calculating max pills to be shown
    slotWidth: {
      type: Number,
      default: 380,
    },
    // format: [...{ key: string, type: string, input: array|object, title: string}]
    options: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const store = useStore()

    const computeFilterables = computed(
      () => store.getters['fsTable/getFilterables']
    )

    const computeFilterablesSelected = computed(
      () => store.getters['fsTable/getFilterablesSelected']
    )

    const computeFilterablesApplied = computed(
      () => store.getters['fsTable/getFilterablesApplied']
    )

    const computeFilterablesAppliedCount = computed(
      () => store.getters['fsTable/getFilterablesAppliedCount']
    )

    const computeMaxVisiblePillsCount = computed(() => {
      // the filter manu itself
      const filterMenuWidth = 145
      // 45px allowance for the last element
      const avialableWidth = props.slotWidth - filterMenuWidth - 45
      // based on use case / an wild guess (I can't help more on it as of now)
      const averageWidthPerPill = 125
      // least possible value for making sure UI won't be broken
      return Math.floor(avialableWidth / averageWidthPerPill)
    })

    // compute the applied filter pills that are always visible
    const computeAppliedPillsVisible = computed(() => {
      let n = 1
      const visiblePills = {}

      for (const k in computeFilterablesApplied.value) {
        if (n > computeMaxVisiblePillsCount.value) {
          break
        }
        visiblePills[k] = computeFilterablesApplied.value[k]
        n++
      }
      return visiblePills
    })

    // compute the applied filter pills that are concealed in the last pill
    const computeAppliedPillsConcealed = computed(() => {
      let n = 1
      const concealedPills = {}

      for (const k in computeFilterablesApplied.value) {
        if (n <= computeMaxVisiblePillsCount.value) {
          n++
          continue
        }
        concealedPills[k] = computeFilterablesApplied.value[k]
      }
      // console.log({ concealedPills })
      return concealedPills
    })

    const computeAppliedPillsConcealedCount = computed(() => {
      return Object.keys(computeAppliedPillsConcealed.value).length
    })

    function getFiltersTitle(filter) {
      if (filter.type === 'checkbox') {
        if (filter.store.length > 1) {
          return filter.title
        } else {
          return filter.input.find((f) => f.value === filter.store[0]).text
        }
      }
      if (filter.type === 'radio') {
        return filter.input.find((f) => f.value === filter.store).text
      }
      return filter.title
    }

    function getFiltersTitleCount(filter) {
      if (filter.type === 'checkbox') {
        return filter.store.length
      }
      return 1
    }

    function setFilterables(options) {
      options.forEach((filter) => {
        const payload = {
          key: filter.key,
          // input type (checkbox, radio, switch, range)
          type: filter.type,
          // input type options (array of objects [...{text:string, value:any}])
          input: filter.input,
          // two-way data binding store aka model
          store: getFilterStore(filter),
          // title for menu group & pill
          title: filter.title,
        }
        if (payload.key) {
          store.commit('fsTable/addFilterable', payload)
        }
      })
    }

    function getFilterStore(filter) {
      // currently checkbox can not have default checked items
      if (filter.type === 'checkbox') return []

      // if (filter.type === 'checkbox-keyed') return null

      // range can have default range selection
      if (filter.type === 'range') {
        if (
          Array.isArray(filter.input.value) &&
          filter.input.value.length === 2
        ) {
          return [filter.input.value[0], filter.input.value[1]]
        }
        // fallback to empty array or [0, 0]
        return [0, 0]
      }

      // all others (radio & toggle) have null type, it means
      // it'll contain single value set by the filter input
      return null
    }

    // reset logic on mounted
    onMounted(() => {
      if (props.forceReset === true) {
        store.commit('fsTable/resetFilterables')
        return
      }
      // different id means we're in different table-view
      if (props.fstId !== store.state.fsTable.fstId) {
        store.commit('fsTable/resetFilterables')
      }
    })

    // using watch to set filterables insted of onMounted
    // to make sure we'll wait for the async data to be ready
    watch(
      () => cloneDeep(props.options),
      function(updated) {
        // todo: fstid & force check
        if (
          Object.keys(computeFilterables.value).length < props.options.length
        ) {
          setFilterables(updated)
        }
      },
      { immediate: true }
    )

    function onFilterUpdate(filter, $event, opt) {
      store.commit('fsTable/onFilterUpdate', { filter, $event, opt })
    }

    function onFilterApply(k) {
      store.commit('fsTable/onFilterApply', [k])
    }

    function onFilterApplySelected() {
      const keys = []
      const selected = computeFilterablesSelected.value
      for (const k in selected) {
        keys.push(selected[k].key)
      }

      store.commit('fsTable/onFilterApply', keys)
    }

    function onFilterClear(k) {
      store.commit('fsTable/onFilterClear', k)
    }

    function onFilterClearAll() {
      store.commit('fsTable/onFilterClearAll')
    }

    return {
      //
      computeFilterables,
      computeFilterablesSelected,
      computeFilterablesApplied,
      computeFilterablesAppliedCount,
      computeMaxVisiblePillsCount,
      //
      computeAppliedPillsVisible,
      computeAppliedPillsConcealed,
      computeAppliedPillsConcealedCount,
      //
      onFilterUpdate,
      onFilterApply,
      onFilterApplySelected,
      onFilterClear,
      onFilterClearAll,
      getFiltersTitle,
      getFiltersTitleCount,
    }
  },
})
</script>

<style lang="scss">
.filterGroupsContainer {
  min-width: 241px;
}
@media screen and (min-width: 640px) {
  .filterGroupsContainer {
    width: auto;
  }
}
.filter-pills-count {
  height: 17px;
  width: 17px;
  font-size: 13px;
  line-height: 17px;
  @apply inline-block text-center text-white bg-blue-700 rounded-full;
}
.selected-filter-pills-count {
  height: 17px;
  width: 17px;
  font-size: 13px;
  line-height: 17px;
  @apply inline-block text-center text-blue-900 bg-gray-200 rounded-full font-bold;
}

.filter-base-dropdown-button {
  @apply flex items-center gap-1 px-2 py-1.5 border rounded-full hover:bg-white text-14px;
}

.min-w-filter-pill {
  min-width: 12rem;
}
.fs-table-filter-dropdown-menu {
  min-width: 181px;
  width: auto;
  max-width: calc(100vw - 50px);
}
@media screen and (min-width: 640px) {
  .fs-table-filter-dropdown-menu {
    min-width: 330px;
    width: 50vw;
  }
}
.is-applied {
  @apply bg-blue-500 text-oWhite;

  &:hover {
    @apply bg-blue-600 text-oWhite;
  }
}
</style>

<template>
  <section>
    <div class="flex">
      <KeepAlive>
        <TDropdown variant="filterMenu">
          <template
            #trigger="{
              mousedownHandler,
              focusHandler,
              blurHandler,
              keydownHandler,
              isShown,
            }"
          >
            <button
              class="filter-base-dropdown-button app-form-reset"
              :class="{
                'bg-gray-100 text-gray-700 border-gray-200': !isShown,
                'bg-white text-oDark border-gray-200 shadow-md': isShown,
              }"
              aria-label="Filter menu"
              aria-haspopup="true"
              @mousedown="mousedownHandler"
              @focus="focusHandler"
              @blur="blurHandler"
              @keydown="keydownHandler"
            >
              <i class="fas fa-filter text-oCharcoal" />

              <span class="font-semibold">Filter</span>

              <span
                :class="`filter-pills-count`"
                :style="
                  `visibility: ${
                    computeFilterablesAppliedCount > 0 ? 'visible' : 'hidden'
                  }`
                "
              >
                {{ computeFilterablesAppliedCount }}
              </span>

              <i class="fas fa-chevron-down text-oCharcoal" />
            </button>
          </template>

          <template v-slot:default="{ hide, blurHandler }">
            <div
              class="gap-2 filterGroupsContainer md:flex md:flex-wrap md:p-1.5"
            >
              <FSTableFilterGroup
                v-for="filter in computeFilterables"
                :key="filter.key"
                :name="filter.title"
              >
                <template v-if="filter.type === 'radio'">
                  <t-radio-group
                    :name="filter.key"
                    :options="filter.input"
                    :value="filter.store"
                    @input="onFilterUpdate(filter, $event)"
                  />
                </template>

                <template v-else-if="filter.type === 'checkbox'">
                  <t-checkbox-group
                    :name="filter.key"
                    :options="filter.input"
                    :value="filter.store"
                    @input="onFilterUpdate(filter, $event)"
                  />
                </template>

                <template v-else-if="filter.type === 'checkbox-keyed'">
                  <template v-for="(checkboxKeyed, cki) in filter.input">
                    <TCheckbox
                      :key="checkboxKeyed.key"
                      :name="checkboxKeyed.key"
                      :variant="`lg`"
                      :value="checkboxKeyed.input.checkedValue"
                      :unchecked-value="checkboxKeyed.input.uncheckedValue"
                      :model="filter.input[cki].store"
                      :label="checkboxKeyed.input.text"
                      wrapped
                      @input="
                        onFilterUpdate(filter, $event, {
                          checkboxKeyed,
                          checkboxKeyedIndex: cki,
                        })
                      "
                    />
                  </template>
                </template>

                <template v-else-if="filter.type === 'toggle'">
                  <t-toggle
                    :name="filter.key"
                    :value="filter.input.checkedValue"
                    :unchecked-value="filter.input.uncheckedValue"
                    :checked-label="filter.input.checkedLabel"
                    :unchecked-label="filter.input.uncheckedLabel"
                    :model="filter.store"
                    @input="onFilterUpdate(filter, $event)"
                  />
                </template>

                <template v-else-if="filter.type === 'range'">
                  <RangeSlider
                    :value="filter.store"
                    :unit="filter.input.unit"
                    :max="filter.input.max"
                    :min="filter.input.min"
                    @input="onFilterUpdate(filter, $event)"
                  />
                </template>
              </FSTableFilterGroup>
            </div>

            <div class="flex gap-4 justify-end p-2 mt-4">
              <AppButton
                :text="`Clear`"
                :variant="`secondary`"
                :height="`33px`"
                @click="
                  hide()
                  onFilterClearAll()
                "
                @blur="blurHandler"
              />

              <AppButton
                :text="`Apply Filter`"
                :variant="`green`"
                :height="`33px`"
                @click="
                  hide()
                  onFilterApplySelected()
                "
                @blur="blurHandler"
              />
            </div>
          </template>
        </TDropdown>
      </KeepAlive>
      <!-- /filter-menu -->

      <!-- filter-pills -->
      <div
        class="flex flex-wrap gap-3 items-center ml-2"
        :style="`width: ${slotWidth - 45}px`"
      >
        <div
          v-for="filter in computeAppliedPillsVisible"
          :key="`filter-pills-${filter.key}`"
        >
          <TDropdown variant="filterPill">
            <template
              #trigger="{
              mousedownHandler,
              focusHandler,
              blurHandler,
              keydownHandler,
              isShown,
            }"
            >
              <button
                class="filter-base-dropdown-button app-form-reset is-applied "
                :class="{
                  'bg-gray-100 text-gray-700 border-gray-200': !isShown,
                  'bg-white text-oDark border-gray-200 shadow-md': isShown,
                }"
                aria-label="Filter pill menu"
                aria-haspopup="true"
                @mousedown="mousedownHandler"
                @focus="focusHandler"
                @blur="blurHandler"
                @keydown="keydownHandler"
              >
                {{ getFiltersTitle(filter) }}
                <span :class="`selected-filter-pills-count`">
                  {{ getFiltersTitleCount(filter) }}
                </span>

                <i class="pl-1 font-normal fas fa-chevron-down" />
              </button>
            </template>

            <div class="p-3" slot-scope="{ hide, blurHandler }">
              <strong>{{ filter.title }}</strong>

              <section v-if="filter.type === 'radio'" class="my-3">
                <t-radio-group
                  :name="filter.key"
                  :options="filter.input"
                  :value="computeFilterables[filter.key].store"
                  @input="onFilterUpdate(filter, $event)"
                  @blur="blurHandler"
                />
              </section>

              <section v-else-if="filter.type === 'checkbox'" class="my-3">
                <t-checkbox-group
                  :name="filter.key"
                  :options="filter.input"
                  :value="computeFilterables[filter.key].store"
                  @input="onFilterUpdate(filter, $event)"
                  @blur="blurHandler"
                />
              </section>

              <template v-else-if="filter.type === 'checkbox-keyed'">
                <template v-for="(checkboxKeyed, cki) in filter.input">
                  <TCheckbox
                    :key="checkboxKeyed.key"
                    :name="checkboxKeyed.key"
                    :variant="`lg`"
                    :value="checkboxKeyed.input.checkedValue"
                    :unchecked-value="checkboxKeyed.input.uncheckedValue"
                    :model="filter.input[cki].store"
                    :label="checkboxKeyed.input.text"
                    wrapped
                    @input="
                      onFilterUpdate(filter, $event, {
                        checkboxKeyed,
                        checkboxKeyedIndex: cki,
                      })
                    "
                  />
                </template>
              </template>

              <section v-else-if="filter.type === 'toggle'" class="my-3">
                <t-toggle
                  :name="filter.key"
                  :value="filter.input.checkedValue"
                  :unchecked-value="filter.input.uncheckedValue"
                  :checked-label="filter.input.checkedLabel"
                  :unchecked-label="filter.input.uncheckedLabel"
                  :model="filter.store"
                  @input="onFilterUpdate(filter, $event)"
                />
              </section>

              <section v-else-if="filter.type === 'range'" class="my-3">
                <RangeSlider
                  :value="filter.store"
                  :unit="filter.input.unit"
                  :max="filter.input.max"
                  :min="filter.input.min"
                  @input="onFilterUpdate(filter, $event)"
                />
              </section>

              <div class="flex gap-2 justify-end mt-6">
                <AppButton
                  :text="`Clear`"
                  :variant="`secondary`"
                  :height="`32px`"
                  @click="
                    hide()
                    onFilterClear(filter.key)
                  "
                  @blur="blurHandler"
                />

                <AppButton
                  :text="`Apply`"
                  :variant="`green`"
                  :height="`32px`"
                  @click="
                    hide()
                    onFilterApply(filter.key)
                  "
                  @blur="blurHandler"
                />
              </div>
            </div>
          </TDropdown>
        </div>

        <!-- filter-pills-last-item -->
        <TDropdown
          v-if="computeAppliedPillsConcealedCount > 0"
          variant="filterPill"
        >
          <template
            #trigger="{
                mousedownHandler,
                focusHandler,
                blurHandler,
                keydownHandler,
                isShown,
              }"
          >
            <button
              class="flex justify-center items-center py-1 px-1 text-sm text-black rounded-full border border-gray-200 cursor-pointer app-form-reset hover:bg-white"
              style="height:35px; width:35px"
              :class="{
                'bg-gray-100 text-gray-700 border-gray-200': !isShown,
                'bg-white text-oDark border-gray-200 shadow-md': isShown,
              }"
              aria-label="Filter pill menu"
              aria-haspopup="true"
              @mousedown="mousedownHandler"
              @focus="focusHandler"
              @blur="blurHandler"
              @keydown="keydownHandler"
            >
              {{ computeAppliedPillsConcealedCount }}+
            </button>
          </template>

          <div
            v-for="filter in computeAppliedPillsConcealed"
            :key="`inside-last-pill-${filter.key}`"
            class="p-2"
          >
            <TDropdown variant="filterPill">
              <template
                #trigger="{
                  mousedownHandler,
                  focusHandler,
                  blurHandler,
                  keydownHandler,
                  isShown,
                }"
              >
                <button
                  class="filter-base-dropdown-button app-form-reset flex
                items-center justify-between"
                  :class="{
                    'bg-blue-500 text-oWhite is-applied': !isShown,
                    'bg-blue-500 text-oWhite is-applied shadow-md': isShown,
                  }"
                  aria-label="Filter pill menu"
                  aria-haspopup="true"
                  @mousedown="mousedownHandler"
                  @focus="focusHandler"
                  @blur="blurHandler"
                  @keydown="keydownHandler"
                >
                  {{ filter.title }}

                  <i class="pl-3 font-normal fas fa-chevron-down" />
                </button>
              </template>

              <div class="p-3" slot-scope="{ hide, blurHandler }">
                <strong>{{ filter.title }}</strong>

                <section v-if="filter.type === 'radio'" class="my-3">
                  <t-radio-group
                    :name="filter.key"
                    :options="filter.input"
                    :value="computeFilterables[filter.key].store"
                    @input="onFilterUpdate(filter, $event)"
                    @blur="blurHandler"
                  />
                </section>

                <section v-else-if="filter.type === 'checkbox'" class="my-3">
                  <t-checkbox-group
                    :name="filter.key"
                    :options="filter.input"
                    :value="computeFilterables[filter.key].store"
                    @input="onFilterUpdate(filter, $event)"
                    @blur="blurHandler"
                  />
                </section>

                <section v-else-if="filter.type === 'toggle'" class="my-3">
                  <t-toggle
                    :name="filter.key"
                    :value="filter.input.checkedValue"
                    :unchecked-value="filter.input.uncheckedValue"
                    :checked-label="filter.input.checkedLabel"
                    :unchecked-label="filter.input.uncheckedLabel"
                    :model="filter.store"
                    @input="onFilterUpdate(filter, $event)"
                  />
                </section>

                <section v-else-if="filter.type === 'range'" class="my-3">
                  <RangeSlider
                    :value="filter.store"
                    :unit="filter.input.unit"
                    :max="filter.input.max"
                    :min="filter.input.min"
                    @input="onFilterUpdate(filter, $event)"
                  />
                </section>

                <div class="flex gap-2 justify-end mt-6">
                  <AppButton
                    :text="`Clear`"
                    :variant="`secondary`"
                    :height="`32px`"
                    @click="
                      hide()
                      onFilterClear(filter.key)
                    "
                    @blur="blurHandler"
                  />

                  <AppButton
                    :text="`Apply`"
                    :variant="`green`"
                    :height="`32px`"
                    @click="
                      hide()
                      onFilterApply(filter.key)
                    "
                    @blur="blurHandler"
                  />
                </div>
              </div>
            </TDropdown>
          </div>

          <!-- <div
              v-for="filter in filterablesApplied"
              :key="`last-pill-${filter.key}`"
              class="p-3"
              slot-scope="{ hide, blurHandler }"
            >
              <strong>{{ filter.title }}</strong>

              <section v-if="filter.type === 'radio'" class="my-3">
                <t-radio-group
                  :name="filter.key"
                  :options="filter.input"
                  :value="computeFilterables[filter.key].store"
                  @input="onFilterUpdate(filter, $event)"
                  @blur="blurHandler"
                />
              </section>

              <section v-else-if="filter.type === 'checkbox'" class="my-3">
                <t-checkbox-group
                  :name="filter.key"
                  :options="filter.input"
                  :value="computeFilterables[filter.key].store"
                  @input="onFilterUpdate(filter, $event)"
                  @blur="blurHandler"
                />
              </section>

              <section v-else-if="filter.type === 'toggle'" class="my-3">
                <t-toggle
                  :name="filter.key"
                  :value="filter.input.checkedValue"
                  :unchecked-value="filter.input.uncheckedValue"
                  :checked-label="filter.input.checkedLabel"
                  :unchecked-label="filter.input.uncheckedLabel"
                  :model="filter.store"
                  @input="onFilterUpdate(filter, $event)"
                />
              </section>

              <section v-else-if="filter.type === 'range'" class="my-3">
                <RangeSlider
                  :value="filter.store"
                  :unit="filter.input.unit"
                  :max="filter.input.max"
                  @input="onFilterUpdate(filter, $event)"
                />
              </section>

              <div class="flex gap-2 justify-end mt-6">
                <AppButton
                  :text="`Clear`"
                  :variant="`secondary`"
                  :height="`32px`"
                  @click="
                    hide()
                    onFilterClear(filter.key)
                  "
                  @blur="blurHandler"
                />

                <AppButton
                  :text="`Apply`"
                  :variant="`green`"
                  :height="`32px`"
                  @click="
                    hide()
                    onFilterApply(filter.key)
                  "
                  @blur="blurHandler"
                />
              </div>
            </div> -->
        </TDropdown>
      </div>
    </div>
  </section>
</template>
