<template>
  <div class="px-2 sliderContainer">
    <!-- <vue-slider v-model="range" :enable-cross="false" :min="min" :max="max" /> -->

    <div class="flex gap-2 mt-2 text-center">
      <div class="flex-1  ">
        <small class="px-1 text-gray-700">Minimum ({{ unit }})</small>
        <div class="font-semibold flex  items-center justify-around ">
          <AppInput class="text-center " type="text" v-model="range[0]" />
        </div>
      </div>

      <div class="flex-1  ">
        <small class="px-1 text-gray-700">Maximum ({{ unit }})</small>
        <div class="font-semibold flex  items-center justify-around ">
          <AppInput class="text-center" type="text" v-model="range[1]" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'

export default {
  name: 'RangeSlider',
  components: {
    // VueSlider,
  },
  props: {
    value: Array,
    unit: String,
    min: {
      type: Number,
      default: 0,
      required: false,
    },
    max: {
      type: Number,
      default: 9999,
      required: false,
    },
  },
  data() {
    return {
      range: [0, 0],
      changeTimeout: null,
    }
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(v) {
        if (Array.isArray(v) && v.length === 2) {
          this.range = v
        }
        return
      },
    },
    range: {
      deep: true,
      immediate: false,
      handler(v) {
        clearTimeout(this.changeTimeout)
        this.changeTimeout = setTimeout(() => {
          this.$emit('input', v)
        }, 600)
      },
    },
  },
  mounted() {
    // todo: check data type
    console.warn({ RS: this.range })
    // this.value = this.range
  },
}
</script>

<style>
.vue-slider-process {
  background-color: black !important;
}
.vue-slider-dot-handle {
  border-color: black !important;
}
.vue-slider:hover .vue-slider-process {
  border-color: rgba(0, 0, 0, 0.808) !important;
}
.vue-slider:hover .vue-slider-dot-handle {
  border-color: rgba(0, 0, 0, 0.808) !important;
}
.sliderContainer {
  width: auto;
}
@media screen and (min-width: 640px) {
  .sliderContainer {
    max-width: 231px;
  }
}
</style>
