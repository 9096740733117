export const exportHeaderGroup = {
  trip: [
    { title: 'Id', key: 'id' },
    { title: 'Rider', key: 'rider' },
    { title: 'Rider Phone Number', key: 'phone_number' },
    { title: 'Vehicle QR', key: 'vehicle_qr' },
    { title: 'Pickup Time', key: 'pickup_time' },
    { title: 'Pickup Location', key: 'pickup_location' },
    { title: 'Drop off Location', key: 'dropoff_location' },
    { title: 'Drop off Time', key: 'dropoff_time' },
    { title: 'Duration', key: 'duration' },
    { title: 'Currency', key: 'currency' },
    { title: 'Charge', key: 'charge' },
    { title: 'Group Trip', key: 'group_trip' },
    { title: 'Feedback', key: 'feedback' },
    { title: 'Illegally Parked', key: 'illegally_parked' },
    { title: 'Outside Service Area', key: 'went_outside_geofence' },
    { title: 'Inside Restricted Area', key: 'went_inside_restricted_area' },
    { title: 'Inside Slowzone Area', key: 'went_inside_slowzone_area' },
    { title: 'Trip Paused', key: 'was_paused' },
    { title: 'Voucher Applied', key: 'voucher_applied' },
    { title: 'Promo Applied', key: 'promo_applied' },
    { title: 'Pass Applied', key: 'pass_applied' },
    { title: 'Rider Balance Negative', key: 'rider_balance_negative' },
    { title: 'Balance Refund', key: 'balance_refund' },
    { title: 'Pre-Authorized', key: 'pre_authorized' },
    { title: 'Status', key: 'status' },
  ],
  rider: [
    { title: 'Id', key: 'id' },
    { title: 'Name', key: 'name' },
    { title: 'Phone Number', key: 'phone_number' },
    { title: 'Joining Date', key: 'joining_date' },
    { title: 'Last Login', key: 'last_login' },
    { title: 'Last Trip Status', key: 'last_trip_status' },
    { title: 'Default Card', key: 'default_card' },
    { title: 'Device', key: 'device' },
    { title: 'Document Verification', key: 'document_verification' },
    { title: 'Currency', key: 'currency' },
    { title: 'Wallet Balance', key: 'wallet_balance' },
  ],
  vehicle: [
    { title: 'Id', key: 'id' },
    { title: 'Name', key: 'name' },
    { title: 'Vehicle Type', key: 'vehicle_type' },
    { title: 'Battery', key: 'battery' },
    { title: 'IMEI', key: 'imei' },
    { title: 'QRCode', key: 'qr_code' },
    { title: 'Last Connected', key: 'last_connected' },
    { title: 'Last Located', key: 'last_located' },
    { title: 'Last Location', key: 'last_location' },
    { title: 'Charging Pick', key: 'charging_pick' },
    { title: 'On Maintenance', key: 'maintenance' },
    { title: 'Rebalance', key: 'rebalance' },
    { title: 'Swap Battery', key: 'swap_battery' },
    { title: 'Sleep Mode', key: 'hibernate_mode' },
    { title: 'Geofence Alert', key: 'geo_fence_alert' },
    { title: 'Restricted Alert', key: 'restricted_alert' },
    { title: 'Slowzone Alert', key: 'slowzone_alert' },
    { title: 'IOT Fault', key: 'iot_fault' },
    { title: 'Low Battery', key: 'low_battery' },
    { title: 'On Charging', key: 'is_charging' },
    { title: 'On Parking', key: 'is_parking' },
    { title: 'On Reservation', key: 'is_reserved' },
    { title: 'On Ride', key: 'is_on_ride' },
    { title: 'Idle', key: 'is_idle' },
    { title: 'Heart Beat', key: 'heartbeat' },
    { title: 'Lock Status', key: 'lock_status' },
  ],
  payment: [
    { title: 'Invoice Id', key: 'invoice_id' },
    { title: 'Payer Name', key: 'payer_name' },
    { title: 'Transaction Time', key: 'transaction_time' },
    { title: 'Payment Method', key: 'payment_method' },
    { title: 'Payment Ref', key: 'payment_ref' },
    { title: 'Payment For', key: 'payment_for' },
    { title: 'Currency', key: 'currency' },
    { title: 'Amount', key: 'amount' },
    { title: 'Refund', key: 'refund' },
    { title: 'Status', key: 'status' },
  ],
  revenue: [
    { title: 'Invoice Id', key: 'invoice_id' },
    { title: 'Tax Id', key: 'tax_id' },
    { title: 'Rider', key: 'rider' },
    { title: 'Payer Role', key: 'payer_role' },
    { title: 'Transaction Time', key: 'transaction_time' },
    { title: 'Payment Method', key: 'payment_method' },
    { title: 'Payment Ref', key: 'payment_ref' },
    { title: 'Payment For', key: 'payment_for' },
    { title: 'Currency', key: 'currency' },
    { title: 'Amount', key: 'amount' },
    { title: 'Status', key: 'status' },
  ],
  triggerPayment: [
    { title: 'Invoice Id', key: 'invoice_id' },
    { title: 'Rider', key: 'rider' },
    { title: 'Credit Card', key: 'credit_card' },
    { title: 'Currency', key: 'currency' },
    { title: 'Amount', key: 'amount' },
    { title: 'Retry Count', key: 'retry_count' },
    { title: 'Payment Ref', key: 'payment_ref' },
    { title: 'Last Triggered At', key: 'last_triggered_at' },
    { title: 'Last Triggered Status', key: 'last_triggered_status' },
  ],
  reports: [
    { title: 'Report ID ', key: 'report_id' },
    { title: 'Report By', key: 'report_by' },
    { title: 'Vehicle QR', key: 'vehicle_qr' },
    { title: 'Date', key: 'date' },
    { title: 'Report Category', key: 'report_category' },
    { title: 'Phase', key: 'phase' },
    { title: 'Subject', key: 'subject' },
    { title: 'Status', key: 'status' },
  ],
}
