<template>
  <div :class="className ? className : 'fst-grid-item'">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'FSTableGridItem',
  props: {
    className: {
      type: [String, Object],
      required: false,
      default: null,
    },
  },
}
</script>

<style lang="scss" scoped>
@import './$fs-table.scss';
</style>
