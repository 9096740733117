export { default as FSTable } from './FSTable.vue'
export { default as FSTableRow } from './FSTableRow.vue'
export { default as FSTableRowItem } from './FSTableRowItem.vue'
export { default as FSTableGridItem } from './FSTableGridItem.vue'
export { default as FSTableFilter } from '../fs-table-filter/FSTableFilter.vue'

/**
 * Easily manage filter application, import & register inside the methods:{}
 *
 * @param {any} payload
 * @param {String} group
 * @returns void
 */

// exposing dedicated function for applying data range filter conveniently
// it works with the default payload of the DateRangePicker component
// both for apply-data & cancel-date events
export function onApplyFilterDateRange(payload) {
  this.$store.commit('fsTable/filterDateRange', payload)
}

export const generateCurrencyAttributes = ({
  root = '',

  def = {
    amount: 'settled_amount',
    currencyName: 'settled_currency.name',
    currencySymbol: 'settled_currency.symbol',
  },

  actual = {
    amount: 'amount',
    currencyName: 'currency.name',
    currencySymbol: 'currency.symbol',
  },
}) => {
  return {
    root,

    def: {
      key: 'default',
      title: 'Default',
      amount: def.amount,
      currencyName: def.currencyName,
      currencySymbol: def.currencySymbol,
    },

    actual: {
      key: 'actual',
      title: 'Actual',
      amount: actual.amount,
      currencyName: actual.currencyName,
      currencySymbol: actual.currencySymbol,
    },
  }
}
